// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";
// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// RC Tabs
@import "components/rc-tabs/rc-tabs";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";

.fixed-header .app-main {
    padding-top: 0
}

.fixed-sidebar .app-main .app-main__outer {
    padding-left: 0px;
}
.logoImg {
	width: 42px;
}
.app-header__logo .logo-src {
    height: 48px;
    background: url(images/home-logo-light.png);
    background-size: 100% 53px;
	max-width: 42px;
}
.form-check-input {
    margin-left: 1rem;
}
.fixed-header .app-main {
    padding-top: 50px;
}
.user-name-profile {
	margin-left: 10px;
}
.hidden {
	display: none
}
.ckEditorForGroup .ck-editor__editable {
    min-height: 205px !important;
}
.ckEditorForQuizzes .ck-editor__editable {
    min-height: 770px !important;
}
.app-page-title + .rc-tabs {
    max-width: calc(100vw);
}
.app-sidebar {
    flex: 0 0 280px;
    width: 280px;
    transform: translateX(-280px);
}
.header-menu-list li{
	width: 100%
}
.app-header__menu, .app-header__mobile-menu {
    padding: 0 0.5rem;
}
.highlited td{
	background-color:  #dfeafb
}
.marksheet-header-img{
	height: 6.5em;
	padding: 0;
}
.marksheet-header-img img{
	width: 100%;
    height: 90px;
}
@media (max-width: 991.98px) {
	.app-header__menu button{
		display: none
	}
	.app-header__logo {
		padding: 0 0.5rem;
	}
	.mobileView {
		display: inline
	}
	.btn-actions-pane-right {
		display: inline
	}
	.btn-actions-pane-right button{
		padding: 0;
	}
	.hidden-xs {
		display: none !important
	}
	.show-xs {
		display: block !important
	}
	
}